import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIDoc, HelpDoc } from './docs.interfaces';
import { Observable } from 'rxjs/Observable';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  baseUrl = 'assets/data';
  constructor(private http: HttpClient) {}

  getApiDocs(): Observable<HelpDoc> {
    return this.http.get<HelpDoc>('assets/data/apidocs.json').pipe(
      catchError(this.handleError),
      finalize(() => {})
    );
  }

  readFile(path: string): Observable<any> {
    return this.http.get(`assets/codeSamples/${path}`, { responseType: 'text' });
  }

  protected handleError(error: any) {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}
