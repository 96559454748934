import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './unauthorized.component';
import { LayoutComponent } from './layout/layout.component';
import { LoadingComponent } from './layout/loading/loading.component';
import { HomeComponent } from './layout/home/home.component';
import { DiagnosticComponent } from './layout/diagnostic/diagnostic.component';
import { SharedModule } from './shared/shared.module';

import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AuthGuardService as AuthGuard } from './core/guards/auth-guard.service';
import { CoreModule } from './core/core.module';
import { CompanyHeaderInterceptor } from './core/interceptors/companyheader.interceptor';
import { LoadingHttpInterceptor } from './core/interceptors/loadinghttp.interceptor';
import { ConfigService } from './core/services/config.service';

import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { NgxJsonViewerModule } from 'ngx-json-viewer';

export function initializeApp(configService: ConfigService) {
  return () => configService.updateEndpoints();
}

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    LayoutComponent,
    LoadingComponent,
    HomeComponent,
    DiagnosticComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MenuModule,
    ToastModule,
    ConfirmDialogModule,
    ButtonModule,
    NgxJsonViewerModule,
    CoreModule,
    SharedModule,
    RouterModule.forRoot([
      {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'welcome', component: HomeComponent },
          {
            path: 'applications',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule)
          },
          {
            path: 'reports',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
          },
          {
            path: 'records',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./records/records.module').then(m => m.RecordsModule)
          },
          {
            path: 'docs',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule)
          },
          {
            path: 'tools',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule)
          },
          {
            path: 'vendors',
            // canActivate: [AuthGuard],
            // data: { preload: false },
            loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule)
          },
          {
            path: '',
            redirectTo: 'applications',
            pathMatch: 'full'
            // canActivate: [AuthGuard]
          },
          { path: '**', component: UnauthorizedComponent }
        ]
      }
    ])
  ],
  providers: [
    MessageService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CompanyHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
