import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { catchError, finalize, tap } from 'rxjs/operators';

import { RecordType, DataType } from '../interfaces';
import { of, throwError } from 'rxjs';
import { NavigationService } from './navigation.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RecordTypeService {
  apiUrl = environment.myApiUrl;

  constructor(
    protected http: HttpClient,
    protected navService: NavigationService) {
  }

  sampleJson(recordType: RecordType): string {
    let result: String[] = [];

    if (recordType && recordType.recordTypeMaps && recordType.recordTypeMaps.length > 0) {
      recordType.recordTypeMaps.forEach(recordMap => {
        if (recordMap) {
          result.push(`"${recordMap.keyName}":${this.makeDataOf(recordMap.dataType)}`);
        }
      });
    } else {
      result.push(`"error":true`);
      result.push(`"message":"no properties defined"`);
    }
    // return '{consentgiven:true,consentrequired:true,language:"string",displaytype:"string"}'

    return '{ ' + result.join(', ') + ' }';
  }

  private makeDataOf(dataType: DataType) {
    switch (dataType) {
      case DataType.boolean: {
        return true;
      }
      case DataType.number: {
        return 4;
      }
      case DataType.string: {
        return '"a test string"';
      }
      case DataType.date: {
        return `${JSON.stringify(new Date(2020, 1, 1))}`;
      }
      case DataType.currency: {
        return 3.99;
      }
      case DataType.array: {
        return JSON.stringify(['1', '2']);
      }
      case DataType.object: {
        return JSON.stringify({ id: 9, name: 'Steve' });
      }
      default: {
        return 'null';
      }
    }
  }

  // TODO:  use these methods in record components and modules instead of dataservice.
  getRecordTypes(lookup: boolean): Observable<RecordType[]> {
    const path = lookup ? 'lookup/' : '';
    return this.http.get<RecordType[]>(`${this.apiUrl}/recordtype/${path}`).pipe(
      // map(rts => {
      //   return rts;
      // }),
      catchError(this.handleError),
      finalize(() => { })
    );
  }

  getSharedRecordTypes(): Observable<RecordType[]> {
    return this.http.get<RecordType[]>(`${this.apiUrl}/recordtype/shared`).pipe(
      tap(typs => {
        typs.unshift(this.initializeRecordType('default')); // add a blank template ;)
      }),
      catchError(this.handleError)
    );
  }

  getRecordTypeById(recordTypeId: number): Observable<RecordType> {
    if (recordTypeId === 0) {
      return of(this.initializeRecordType());
    }
    return this.http.get<RecordType>(`${this.apiUrl}/recordtype/${recordTypeId}`).pipe(
      // map(rt => {
      //   console.log(rt);
      //   return rt;
      // }),
      catchError(this.handleError),
      finalize(() => { })
    );
  }

  private initializeRecordType(name = ''): RecordType {
    return {
      id: 0,
      name: name,
      description: '',
      collectIP: false,
      collectionTypeId: 3,
      recordTypeMaps: []
    };
  }

  saveRecordType(rt: RecordType): Observable<RecordType> {
    return this.http.post<RecordType>(`${this.apiUrl}/recordtype`, rt).pipe(
      catchError(this.handleError),
      finalize(() => { })
    );
  }

  protected handleError(error: any) {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }


}
