import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { Company } from './core/interfaces';
import { NavigationService } from './core/services/navigation.service';

declare var $: any;

@Component({
  selector: 'app-unauthorized',
  template: `
    <div class="page">
      <div class="p-grid p-justify-center empty-message">
        <div class="p-col-12 p-md-12 p-lg-12 center">
          <i
            class="fa fa-minus-circle fa-5x"
            aria-hidden="true"
            style="color:#d90c1a;"
          ></i>
          <h1>
            Not Authorized
          </h1>
        </div>
      </div>
    </div>
  `
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  private activeCompany;
  private unAuthCompany: Company;
  constructor(
    private authService: AuthService,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.activeCompany = this.authService.company$.subscribe(
      (company: Company) => {
        if (!this.unAuthCompany) {
          this.unAuthCompany = company;
        } else {
          if (this.unAuthCompany.id !== company.id) {
            // its a different company. Try going back.
            this.navService.goHome();
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.activeCompany.unsubscribe();
  }
}
