import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {
  public debugObject = {};
  constructor() {}

  get json() {
    return JSON.stringify(this.debugObject);
  }
}
