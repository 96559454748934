import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Company, User } from '../interfaces';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.myApiUrl;
  public company$: Subject<Company>;
  public activeCompany: Company;
  public currentUser: User;
  public user$: Subject<User>;

  constructor(protected http: HttpClient) {
    this.company$ = new BehaviorSubject<Company>(null);
    this.user$ = new BehaviorSubject<User>(null);
  }

  authenticate(): Observable<any> {
    return this.http.get<User>(this.apiUrl + '/UserContext').pipe(
      tap(u => this.setCurrentUser(u)),
      catchError(this.handleError),
      finalize(() => {})
    );
  }

  setActiveCompany$(company: Company) {
    if (company) {
      window.localStorage.setItem('activeCompanyId', company.id.toString());
      this.activeCompany = company;
      this.company$.next(company);
    }
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
    let companies = user.companies;
    const activeCompanyId = JSON.parse(window.localStorage.getItem('activeCompanyId'));
    const activeCompanies = this.currentUser.companies.filter(c => c.id === activeCompanyId);
    if (activeCompanies && activeCompanies.length > 0) {
      companies = activeCompanies;
    }
    this.setActiveCompany$(companies[0]);
    this.user$.next(user);
  }

  userhasClaim(claimId: number) {
    if (this.currentUser) {
      // first check admin claims.
      if (this.currentUser.adminClaims && this.currentUser.adminClaims.indexOf(claimId) > -1) {
        return true;
      }
      // check claims for active company:
      const claimComp = this.currentUser.companies.find(c => c.id === this.activeCompany.id);
      if (claimComp && claimComp.claims && claimComp.claims.indexOf(claimId) > -1) {
        return true;
      }
    }
    return false;
  }

  private handleError(error: any) {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }
}
