import { Component, OnInit } from '@angular/core';
import { DiagnosticService } from 'src/app/core/services/diagnostic.service';

@Component({
  selector: 'app-diagnostic',
  template: `
    <div class="diagnostic">
      <app-json-view *ngIf="diagnosticService.debugObject" [json]="diagnosticService.json"></app-json-view>
    </div>
  `,
  styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent implements OnInit {
  ngOnInit(): void {
    // console.log('dialog init');
  }
  constructor(public diagnosticService: DiagnosticService) {}
}
