import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'sqlLines'
})
export class SqlLinesPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: any, ...args: any[]): any {

    value = value.replace('WHERE', '\r\nWHERE');
    value = value.replace('FROM', '\r\nFROM');
    value = value.replace('GROUP BY', '\r\nGROUP BY');
    value = value.replace(/", /g, '", \r\n');
    value = value.replace(/, CAST/g, ', \r\nCAST');

    let newGroup = [];
    let splitup = value.split(" ");
    if (splitup) {
      splitup.forEach(element => {
        if (!isNaN(element)) {
          if (element > 1960800000) {
            newGroup.push(`${element} (' ${this.datePipe.transform(element)} ')`)
          } else {
            newGroup.push(element);
          }
        } else {
          newGroup.push(element);
        }
      });
    }

    return newGroup.join(" ");
  }

}
