import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { NavigationService } from '../../core/services/navigation.service';

@Injectable()
export class ClaimGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private nav: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedClaim = route.data.expectedClaim;

    if (!this.auth.userhasClaim(expectedClaim)) {
      this.nav.goToUnAuthorized();
      return false;
    }
    return true;
  }
}
