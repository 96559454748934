import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dg-section',
  template: `
    <div [ngClass]="{ 'hide-me': !show }" [class]="'dg-l-' + l">
      <h1 [class]="'dg-h-' + l" (click)="toggle()">
        <span *ngIf="apiMethod" class="code-method">{{ apiMethod }}</span>
        <span innerHTML="{{ header }}"></span>
      </h1>
      <ng-content *ngIf="show"></ng-content>
    </div>
  `
})
export class SectionComponent implements OnInit {
  @Input() header: string;
  @Input() apiMethod: string;
  @Input() l: number;
  @Input() collapsed: boolean;
  show = true;
  divClass: string;
  constructor() {}

  ngOnInit() {
    if (this.collapsed || this.l > 6) {
      this.toggle();
    }
    this.divClass = 'dg-l-' + this.l;
  }

  toggle() {
    this.show = !this.show;
  }
}
