import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { PlatformMenuItem } from '../interfaces';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  // apiUrl = environment.platformApi;
  themeClass$: BehaviorSubject<string>;
  themeClassKey: string = 'darkOn';
  themes: string[] = [
    'dg-light',
    'dark-theme',
    // 'evidone-theme'
  ];
  public navItems = [
    {
      id: 1,
      label: 'Dashboard',
      icon: 'fa-tachometer'
      // route: '/welcome'
    },
    {
      id: 2,
      productId: 15,
      label: 'Consent as a Service',
      icon: 'fa-cloud',
      // items: [
      //   { label: 'Applications', icon: 'fa-file-code-o', url: `https://caas.${environment.laneDomain}/applications` },
      //   { label: 'Reports', icon: 'fa-line-chart', url: `https://caas.${environment.laneDomain}/reports` },
      //   { label: 'Records', icon: 'fa-database', url: `https://caas.${environment.laneDomain}/records` },
      //   { label: 'User Preferences', icon: 'fa-bolt', url: `https://caas.${environment.laneDomain}/preferences` },
      //   { label: 'Developers', icon: 'fa-life-ring', url: `https://caas.${environment.laneDomain}/docs` }
      // ]
    },
    {
      id: 3,
      productId: 16,
      label: 'Tag Control',
      icon: 'fa-tags',
      items: [
        { label: 'Sites', icon: 'fa-sitemap', url: `${environment.tagControlUrl}/sites` }
      ]
    },
    // {
    //   id: 4,
    //   label: 'Tag Auditor MCM',
    //   icon: 'fa-bar-chart',
    //   items: [
    //     { label: 'DGR Report', icon: 'fa-area-chart' },
    //     { label: 'Latency Report', icon: 'fa-pie-chart' }
    //   ]
    // },
    {
      id: 5,
      productId: 14,
      label: 'User Administration',
      icon: 'fa-users',
      url: `${environment.platformAdminUrl}`
    }
  ];

  constructor() { }

  themeValue() {
    return this.themeClass$.value;
  }

  setTheme(theme: string) {
    window.localStorage.setItem(this.themeClassKey, theme);
    this.themeClass$.next(theme);
  }

  toggleTheme() {
    const i = this.themes.indexOf(this.lsoTheme) === 0 ? 1 : 0;
    this.setTheme(this.themes[i]);
  }

  get lsoTheme() {
    return window.localStorage.getItem(this.themeClassKey);
  }

  getMenu(): Observable<PlatformMenuItem[]> {
    return of(this.navItems);
  }

  // getVendorDetails(vendorId: number): Observable<VendorDetails> {
  //   return this.http.get<VendorDetails>(`${this.apiUrl}/Vendors/DetailsById/${vendorId}`)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  handleError(error: HttpErrorResponse) {
    // console.error(error);
    return throwError(error || 'Server error');
  }
}
