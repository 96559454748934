import { Directive, ElementRef, AfterViewInit, Input, OnChanges, Output } from '@angular/core';
import { DocumentationService } from 'src/app/docs/documentation.service';

@Directive({
  selector: '[appCodeView]'
})
export class CodeViewDirective implements AfterViewInit {
  @Input()
  filePath: string;

  @Input()
  object: string;

  @Input('codeObject')
  set codeObject(codeObject: any) {
    this.object = codeObject;
    this.highlight();
  }
  get codeObject() {
    return this.object;
  }

  constructor(public el: ElementRef, private documentationService: DocumentationService) { }

  ngAfterViewInit() {
    if (this.filePath) {
      this.documentationService.readFile(this.filePath).subscribe(f => {
        this.el.nativeElement.innerHTML = f;
        this.highlight();
      });
    } else {
      this.highlight();
    }

  }

  highlight() {
    if (window['Prism']) {
      if (this.object) {
        this.el.nativeElement.innerHTML = this.object;
      }
      window['Prism'].highlightElement(this.el.nativeElement);
    }
  }

}
