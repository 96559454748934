import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading = false;

  constructor() {}

  public loading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
