import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../services/auth.service';
import { User } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private navService: NavigationService, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.currentUser) {
      // console.log(`auth guard sees undefined user, calling authenticate();`);
      this.authService.authenticate().subscribe((user: User) => {
        const valid = user && user.companies && user.companies.length > 0;
        if (!valid) {
          this.navService.goToUnAuthorized();
        }
        return valid;
      });
    }

    return true;
  }
}
