import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';
import { DataService } from './services/data.service';
import { ClaimGuardService } from './guards/claim-guard.service';
import { LoadingService } from './services/loading.service';
import { OfflineAuthService } from './services/offline-auth.service';
import { DiagnosticService } from './services/diagnostic.service';
import { RecordTypeService } from './services/record-type.service';
import { CacheDataService } from './services/cache-data.service';
import { PlatformService } from './services/platform.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    { provide: DataService, useClass: DataService },
    { provide: AuthService, useClass: AuthService },
    // OfflineAuthService,
    // AuthService,
    NavigationService,
    LoadingService,
    ClaimGuardService,
    DiagnosticService,
    RecordTypeService,
    PlatformService
  ],
  declarations: [],
  exports: []
})
export class CoreModule { }
