import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NavigationService } from '../services/navigation.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import { MessageService } from '../services/message.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private navService: NavigationService //  , private messageService: MessageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request: HttpRequest<any> = req.clone({
      withCredentials: true
    });

    return next.handle(request).pipe(
      catchError((error, caught) => {
        switch (error.status) {
          case 401:
            return this.handle401Error(error);
          case 403:
            return this.handle403Error(error);
          case 500:
            return this.handle500Error(error);
          default:
            return throwError(error);
        }
      })
    ) as any;
  }

  private handle401Error(error: any) {
    this.navService.goToSignOn();
    return throwError(error); // dont really want to throw this but for now...
  }

  private handle403Error(error: any) {
    this.navService.goToUnAuthorized();
    return throwError(error);
  }

  private handle500Error(error: any) {
    // this.messageService.setMessage(`Oops something unexpected happened. Try again. ${error}`);
    return throwError(error);
  }
}
