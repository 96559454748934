import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardCopyDirective } from './directives/clipboard-copy.directive';
import { JsonViewComponent } from './components/json-view/json-view.component';

import { TooltipModule } from 'primeng/tooltip';
import { CodeViewDirective } from './directives/code-view.directive';
import { SectionComponent } from './components/section/section.component';
import { SqlLinesPipe } from './sql-lines.pipe';

@NgModule({
  declarations: [ClipboardCopyDirective, JsonViewComponent, CodeViewDirective, SectionComponent, SqlLinesPipe],
  imports: [CommonModule, TooltipModule],
  exports: [ClipboardCopyDirective, JsonViewComponent, TooltipModule, CodeViewDirective, SectionComponent, SqlLinesPipe]
})
export class SharedModule { }
