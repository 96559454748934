import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CompanyHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const company = this.authService.activeCompany;
    if (company) {
      const modified = req.clone({ setHeaders: { CompanyId: company.id.toString() } });
      return next.handle(modified);
    } else {
      return next.handle(req);
    }
  }
}
