import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class NavigationService {
  constructor(private router: Router) { }

  public goToSignOn() {
    var pathRequested = this.router.routerState.snapshot.url;
    const redirect = `${window.location.protocol}//${environment.ssoUrl}/logout?redirect=${environment.myUrl}${pathRequested}`;
    window.location.href = redirect;
  }

  public logOut() {
    const redirect = `${window.location.protocol}//${environment.ssoUrl}/logout?redirect=${environment.myUrl}`;
    window.location.href = redirect;
  }

  public goHome() {
    this.router.navigate(['./welcome']);
  }

  public goToUnAuthorized() {
    this.router.navigate(['./unauthorized']);
  }
}
