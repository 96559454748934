import { Injectable, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ConfigService implements OnInit {
  localUrl = "localhost:4200";
  devUrl = "caas.evidondev.com";
  qaUrl = "caas.evidonqa.com";
  stageUrl = "caas.evidonstage.com";
  prodUrl = "caas.evidon.com";

  constructor() { }

  ngOnInit() { }

  init() { }

  updateEndpoints() {
    return new Promise<void>((resolve, reject) => {
      const host = window.location.host.toString().toLocaleLowerCase();

      switch (host) {
        case this.localUrl.toLocaleLowerCase():
          this.localConfiguration();
          break;
        case this.devUrl.toLocaleLowerCase():
          this.devConfiguration();
          break;
        case this.qaUrl.toLocaleLowerCase():
          this.qaConfiguration();
          break;
        case this.stageUrl.toLocaleLowerCase():
          this.stageConfiguration();
          break;
        case this.prodUrl.toLocaleLowerCase():
          this.prodConfiguration();
          break;
        default:
          console.log(
            `Error detecting host running from to configure endpoints. Host ${host}`
          );
          break;
      }

      resolve();
    });
  }

  private localConfiguration() {
    environment.production = false;
    environment.myApiUrl = "http://localhost/Caas.Net.Api/api";
    environment.ssoUrl = "localhost/Platform.SignOn.SSO";
    environment.myUrl = "http://localhost:4200";
    environment.platformAdminUrl = "http://platformadmin.evidondev.com";
    environment.tagControlUrl = 'http://tagcontrolv2.evidondev.com';
    environment.diagnosticOn = true;
  }

  private devConfiguration() {
    environment.production = false;
    environment.myApiUrl = "https://caasapi.evidondev.com/api";
    environment.ssoUrl = "sso.evidondev.com";
    environment.myUrl = "https://caas.evidondev.com";
    environment.platformAdminUrl = "http://platformadmin.evidondev.com";
    environment.tagControlUrl = 'http://tagcontrolv2.evidondev.com';
    environment.diagnosticOn = false;
  }

  private qaConfiguration() {
    environment.production = false;
    environment.myApiUrl = "https://caasapi.evidonqa.com/api";
    environment.ssoUrl = "sso.evidonqa.com";
    environment.myUrl = "https://caas.evidonqa.com";
    environment.platformAdminUrl = "http://platformadmin.evidonqa.com";
    environment.tagControlUrl = 'http://tagcontrolv2.evidonqa.com';
    environment.diagnosticOn = false;
  }

  private stageConfiguration() {
    environment.production = false;
    environment.myApiUrl = "https://caasapi.evidonstage.com/api";
    environment.ssoUrl = "sso.evidonstage.com";
    environment.myUrl = "https://caas.evidonstage.com";
    environment.platformAdminUrl = "https://platformadmin.evidonstage.com";
    environment.tagControlUrl = 'https://tagcontrolv2.evidonstage.com';
    environment.diagnosticOn = false;
  }

  private prodConfiguration() {
    environment.production = true;
    environment.myApiUrl = "https://caasapi.evidon.com/api";
    environment.ssoUrl = "sso.evidon.com";
    environment.myUrl = "https://caas.evidon.com";
    environment.platformAdminUrl = "https://platformadmin.evidon.com";
    environment.tagControlUrl = 'https://tagcontrolv2.evidon.com';
    environment.diagnosticOn = false;
  }
}
