import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';



import { MenuItem } from 'primeng/components/common/menuitem';
import { MessageService } from 'primeng/api';

import { User, Company, Claims, PlatformMenuItem } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { LoadingService } from '../core/services/loading.service';
import { environment } from 'src/environments/environment';
import { DiagnosticService } from '../core/services/diagnostic.service';
import { PlatformService } from '../core/services/platform.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('animation', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
          maxHeight: '0',
          paddingTop: '0',
          paddingBottom: '0',
          marginTop: '0',
          marginBottom: '0',
          opacity: '0'
        })
      ),
      state(
        'void',
        style({
          height: '0',
          overflow: 'hidden',
          maxHeight: '0',
          paddingTop: '0',
          paddingBottom: '0',
          marginTop: '0',
          marginBottom: '0'
        })
      ),
      state(
        'visible',
        style({
          height: '*'
        })
      ),
      transition('visible <=> hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class LayoutComponent implements OnInit {
  menuActive: boolean;
  activeMenuId: number = 2; // active by default!
  mouseOnMenu: boolean;
  showExpanded: boolean;

  user: User;
  company: Company;
  private companySubscription;
  productId = 15;
  claimType = Claims;
  companyItems: MenuItem[] = [];
  userItems: MenuItem[];


  navItems: PlatformMenuItem[];

  constructor(
    public authService: AuthService,
    public loadingService: LoadingService,
    private navService: NavigationService,
    private platformService: PlatformService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.user = user;

        this.user.companies.forEach(c => {
          this.companyItems.push({
            label: c.name,
            id: String(c.id),
            command: event => {
              this.authService.setActiveCompany$(c);
            }
          });
        });
        this.userItems = [
          {
            label: 'Sign out',
            command: () => {
              this.navService.logOut();
            }
          }
        ];
      }
    });

    this.companySubscription = this.authService.company$.subscribe((company: Company) => {
      this.company = company;
    });

    this.platformService.getMenu().subscribe(navItems => {
      let mySection = navItems.find(ni => ni.id === 2);  // todo make these product ids
      mySection.items = [];

      // if (this.authService.userhasClaim(Claims.readSomething)) {
      mySection.items.push({ label: 'Applications', icon: 'fa-file-code-o', route: '/applications' });
      mySection.items.push({ label: 'Reports', icon: 'fa-line-chart', route: '/reports' });
      mySection.items.push({ label: 'Records', icon: 'fa-database', route: '/records' });
      // mySection.items.push({ label: 'Vendors', icon: 'fa-tags', route: '/vendors' });
      //  mySection.items.push({ label: 'User Preferences', icon: 'fa-sitemap', route: '/sites' });
      mySection.items.push({ label: 'Developers', icon: 'fa-life-ring', route: '/docs' });
      // }



      this.navItems = navItems;
    });
  }

  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        event.element.style.display = 'block';
        break;
    }
  }
  onAnimationDone(event) {
    switch (event.toState) {
      case 'hidden':
        event.element.style.display = 'none';
        break;

      case 'void':
        event.element.style.display = 'none';
        break;
    }
  }
  mousing(on) {
    this.mouseOnMenu = on;
    this.setMousing(on);
  }
  setMousing(on) {
    if (on) {
      this.showExpanded = true;
    } else {
      setTimeout(() => {
        if (!this.mouseOnMenu) {
          this.showExpanded = false;
        }
      }, 500);
    }
  }

  toggleMenu(on) {
    this.showExpanded = on;
  }
  toggle(id: number, url: string = '') {
    if (this.showExpanded) {
      if (url.length > 0) {
        window.location.href = url;
      }
      this.activeMenuId = this.activeMenuId === id ? null : id;
    } else {
      this.showExpanded = true;
      this.activeMenuId = id;
    }
  }

  onKeydown(event: KeyboardEvent, id: string) {
    // if (event.which === 32 || event.which === 13) {
    //   this.toggle(id);
    //   event.preventDefault();
    // }
  }

  onMenuButtonClick(event: Event) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  get diagnosticOn() {
    return environment.diagnosticOn;
  }
}
