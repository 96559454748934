import { logging } from 'protractor';

export interface User {
  id: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  adminClaims: number[];
  products: number[];
  companies: Company[];
  activeCompanyId: number;
}

export interface Company {
  id: number;
  name: string;
  claims: number[];
  products: number[];
}

export enum Claims {
  ReadApplication = 1500,
  WriteApplication = 1501,
  ReadReports = 1502,
  WriteReports = 1503,
  ReadRecords = 1504,
  WriteRecords = 1505,
  WriteAdmin = 1506
}

// for caas ui
export interface RecordType {
  id: number;
  name: string;
  description: string;
  collectIP: boolean;
  collectionTypeId: number;
  recordTypeMaps: RecordTypeMap[];
}

// export class RecordType implements IRecordType {
//   id: number;  name: string;
//   description: string;
//   collectIP: boolean;
//   collectionTypeId: number;
//   recordTypeMaps: RecordTypeMap[];

// }

export interface RecordTypeMap {
  id: number;
  keyName: string;
  keyLabel: string;
  dataType: DataType;
  description: string;
}

export enum DataType {
  string = 1,
  number = 2,
  object = 3,
  array = 4,
  boolean = 5,
  null = 6,
  date = 7,
  currency = 8
}



export interface Token {
  token: string;
  active: boolean;
}

export class ReportRequest {
  applicationId: number;
  summarize: boolean = false;
  recordTypeId: number;
  groupByRecordTypeMapIds: number[];
  dateRange: Date[];
  startDate: Date;
  endDate: Date;
}

export class ReportDetails {
  applicationName: string;
  recordName: string;
  actionType1_Name: string;
  actionType2_Name: string;
  startDate: string;
  endDate: string;
}

export interface ExecutedReport {
  id: number;
  queryExecutionId: string;
  name: string;
  downloadedCount: number;
  size: number;
  created: Date;
  sqlText?: string;
}

export enum CodeLanguage {
  cURL = 1,
  Javascript = 2,
  Csharp = 3,
  HTTP = 4,
  PowerShell = 5
}











export interface PlatformMenuItem {
  id?: number;
  label: string;
  command?: (event?: any) => void;
  url?: string;
  route?: any;
  icon?: string;
  items?: PlatformMenuItem[];
  productId?: number;
}
